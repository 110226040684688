import React, {
  useMemo,
  useEffect,
  useState,
} from 'react';
import CompliancePortal  from './screens/CompliancePortal';
import './App.css';

function b64DecodeUnicode(str) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const App = () => {
  const [ client_data, setClientData ] = useState();
  const [ load_error, setLoadError ] = useState();
  const {token, preview_mode } = useMemo(() => {
    const params = new URLSearchParams(document.location.search.slice(1));
    return {
      token: params.get('token'),
      preview_mode: params.get('preview_mode'),
    }
  }, []);
  const dealer_info = useMemo(() => {
    const token_data = JSON.parse(b64DecodeUnicode(token.split('.')[1]));
    return {
      bg_color: token_data.bg_color,
      accent_color: token_data.accent_color,
      text_color: token_data.text_color,
      logo_url: token_data.logo_url,
      dealer_name: token_data.dealer_name,
      legal_name: token_data.legal_name,
      privacy_policy_url: token_data.privacy_policy_url,
      token,
      preview_mode,
    }
  },[token, preview_mode]);
  useEffect(() => {
    if (preview_mode) {
      setClientData({});
    } else {
      fetch(`/gdpr/client_info`, {
        headers: { Authorization: `Bearer ${token}`},
      })
        .then(r => r.ok ? r.json() : r.text().then(t => Promise.reject(`Unexpected ${r.status} at ${r.url}: ${t}`)))
        .then(setClientData)
        .catch(setLoadError)
    }
  }, [preview_mode, token]);
  const DealerInfo = React.createContext();
  window.DealerInfo = DealerInfo;
  return (
    <DealerInfo.Provider value={dealer_info}>
      <CompliancePortal client_data={client_data} load_error={load_error}/>
    </DealerInfo.Provider>
  );
}

export default App;
