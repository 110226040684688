import React, {
  useState,
  useEffect,
  useContext,
}                        from 'react';
import styled            from 'styled-components';
import Field             from './components/Field';
import { useMediaQuery } from 'react-responsive'
import ReactToggle       from 'react-toggle';
import useBasicI18N      from '../../hooks/useBasicI18N';

import "react-toggle/style.css" // for ES6 modules
import "./index.css";

const Toggle = styled(ReactToggle)`
  &.react-toggle--checked .react-toggle-track {
    background-color: ${props => props.accent_color};
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track{
    background-color: ${props => props.accent_color};
  }
  &.react-toggle--checked .react-toggle-thumb{
    border-color: ${props => props.accent_color};
  }
`;

const Button = styled.button`
  padding: 1em;
  background-color: ${props => props.disabled ? 'lightgray' : props.accent_color};
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 3px 0px;
  border-radius: 3px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;


const CompliancePortal = ({
  client_data,
  load_error,
}) => {
  const getTranslation = useBasicI18N();
  const big_screen = useMediaQuery({ query: '(min-width: 1224px)' })
  const [ name, setName ] = useState();
  const [ email, setEmail ] = useState();
  const [ phone, setPhone ] = useState();
  const [ updated, setUpdated ] = useState(false);
  const [ policy_accepted, setPolicyAccepted ] = useState(false);
  const [ consents, setConsents ] = useState({});
  useEffect(() => { if(client_data){
    setName(client_data.name);
    setEmail(client_data.emails && client_data.emails[0]);
    setPhone(client_data.phones && client_data.phones[0]);
  }}, [client_data]);
  const {
    bg_color,
    accent_color,
    logo_url,
    dealer_name,
    legal_name,
    text_color,
    privacy_policy_url,
    token,
  } = useContext(window.DealerInfo);
  const sendPreferences = () => fetch('/gdpr/compliance', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_info: {name, email, phone},
      consents,
    }),
  }).then(r => setUpdated(r.ok))
  return (
    <div style={{
      display: 'grid',
      height: '100vh',
      alignItems: 'stretch',
      gridTemplateColumns: big_screen ? '30% 70%' : '1fr',
      gridTemplateRows: big_screen ? '1fr' : '4fr 10fr',
    }}>
      <div style={{
        backgroundColor: bg_color,
        display: 'grid',
        gridTemplateRows: big_screen ? 'auto 1fr auto' : '1fr',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          padding: '3em',
        }}>
          <img style={{
            width: 'auto',
            maxWidth: 'calc(100% - 6em)',
            height: '100%',
            maxHeight: '10em',
          }} src={logo_url}/>
        </div>
        {big_screen && <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          fontSize: '3em',
          color: text_color,
          padding: '1rem',
          paddingTop: '10rem',
          textAlign: 'center',
        }}>
          {getTranslation('data_protection_portal_of', {dealer_name})}
        </div>}
        {big_screen && <div style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          padding: '1em',
          color: text_color,
        }}>
          Made with ️🐼 by Walcu
        </div>}
      </div>
      { updated && (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {getTranslation('data_updated_successfully')}
        </div>
      ) || client_data && (
        <div style={{
          boxShadow: big_screen ? 'rgba(0, 0, 0, 0.8) 15px 0px 30px 0px' : 'rgba(0, 0, 0, 0.3) 0px -15px 30px 0px',
          display: 'flex',
          flexDirection: 'column',
          padding: '2em',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}>
          <h1>{getTranslation('your_information')}</h1>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
            gridGap: '1em',
            width: '100%',
          }}>
            <Field label={getTranslation('name')} value={name} onChange={setName}/>
            <Field label={getTranslation('email')} value={email} onChange={setEmail} />
            <Field label={getTranslation('phone')} value={phone} onChange={setPhone}/>
          </div>
          <h1>{getTranslation('consents')}</h1>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: '1em',
            width: '100%',
          }}>
            <div style={{display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '1em 0.5em', alignItems: 'center'}}>
              <Toggle
                accent_color={accent_color}
                checked={policy_accepted}
                onChange={v => setPolicyAccepted(v.target.checked)}
              />
              <div dangerouslySetInnerHTML={{__html: getTranslation('gdpr_privacy_policy', {
                legal_name,
                privacy_policy_url,
              })}}/>
              <Toggle
                accent_color={accent_color}
                checked={consents.marketing}
                onChange={v => (checked => setConsents(c => ({...c, marketing: checked})))(v.target.checked)}
              />
              {getTranslation('gdpr_marketing', {legal_name})}
              <Toggle
                accent_color={accent_color}
                checked={consents.profiling}
                onChange={v => (checked => setConsents(c => ({...c, profiling: checked})))(v.target.checked)}
              />
              {getTranslation('gdpr_profiling', {legal_name})}
              <Toggle
                accent_color={accent_color}
                checked={consents.third_party}
                onChange={v => (checked => setConsents(c => ({...c, third_party: checked})))(v.target.checked)}
              />
              {getTranslation('gdpr_third_party', {legal_name})}
            </div>
          </div>
          <div style={{
            display: 'flex',
            width: '100%',
            padding: '1em',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Button
              accent_color={accent_color}
              disabled={!policy_accepted}
              onClick={sendPreferences}
            >
              {getTranslation('send')}
            </Button>
          </div>
        </div>
      ) || load_error && (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {getTranslation('already_validated')}
        </div>
      ) || (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          {getTranslation('data_loading')}
          <div className="lds-ring">
            <div style={{
              border: `3px solid ${bg_color}`,
              borderColor: `${bg_color} transparent transparent transparent`,
            }}>
            </div><div>
            </div><div>
            </div><div>
            </div></div>
        </div>
      )}
    </div>
  );
};

export default CompliancePortal;
